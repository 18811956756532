.monsieurbiz-uploader .hidden {
  display: none;
}
.monsieurbiz-uploader .filepond--root {
  cursor: pointer;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  background: #FFFFFF;
}
.monsieurbiz-uploader .filepond--drop-label {
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}
.monsieurbiz-uploader .filepond--file-info {
  color: #ffffff;
  cursor: pointer;
}
.monsieurbiz-uploader  .filepond--drop-label label {
  cursor: pointer;
}
.monsieurbiz-uploader .filepond--file {
  background: #767676;
  border-radius: 0.5em;
  font-size: 1.05em;
  color: rgba(0, 0, 0, 0.87);
}
.monsieurbiz-uploader.error .filepond--root {
  border-radius: 0.285714rem;
  background-color: #FFF6F6;
  border: 1px solid rgb(224, 180, 180);
  color: #9F3A38;
}
.monsieurbiz-uploader.error  .filepond--drop-label {
  color: #9F3A38;
}
